@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;

.main {
  width: 100%;

  .section_title {
    @include font.heading3;
  }

  .section_sub_title {
    @include font.body1;
    padding-top: 12px;
    padding-bottom: 12px;
    color: v.$grey100;
  }

  .chart_block {
    border: 1px solid v.$grey50;
    border-radius: 8px;
    padding: 30px 40px;

    .chart_block_title_row {
      // background: #f00;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .chart_block_title {
        // background-color: #0f0;
        @include font.heading4;
        white-space: normal;
        overflow-wrap: break-word;
        // word-break: break-word;
        word-break: break-all;
      }

      .chart_block_sub_title {
        // background-color: #00f;
        @include font.body1;
        color: v.$grey100;
        text-align: right;

        .number {
          font-weight: 600;
        }
      }
    }
  }
  
  // .main_chart {}
  
  .main_stacked_bar_chart {
    // background-color: #f00;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}