// Global scss variable, can be imported to any scss file

/* Color Variable */
/* Neutral/Amway Black - Heading */
$amwayBlack: #2c2c2c;
/* Neutral/White */
$white: #ffffff;
/* Neutral/Grey 25 - Flood */
$grey25: #f4f4f4;
/* Neutral/Grey 50 - DIvider */
$grey50: #dfdfdf;
/* Neutral/Grey 100 - Body */
$grey100: #6f6f6f;
/* Neutral/Grey 75 - Input */
$grey75: #bab9b8;

$red: #FF3A00;
// --semantic-red, #FF3A00);

// footer green
$green2: #104c48;
$green2Hover:#18756e;
// linear-gradient(0deg, #104C48, #104C48), linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15));
/* Green */
// Neon Green
$green: #0dddcd;
// Dark Green
$darkGreen: #073431;
// Green - Peptide Properties
$green4: #1A8074;
// color: var(--green, #1A8074);
$chartHoverGreen: #2FEBD3;
$purple: #856DF0;
$hoverPurple: #978FF3;

/* Gradient 1 */
$Gradient1: linear-gradient(180deg, #856df0 0%, #1ed7c9 100%);
/* Gradient 2 */
$Gradient2: radial-gradient(59.44% 60.12% at 16.95% 39.88%, rgba(255, 255, 255, 0) 0%, #856DF0 12.7%, #98D1F1 43.75%, #1ED7C9 63.02%, rgba(255, 255, 255, 0) 89.06%);
$homePageGradient2BgColor: radial-gradient(59.44% 60.12% at 16.95% 39.88%, rgba(255, 255, 255, 0) 0%, #856DF0 12.7%, #98D1F1 43.75%, #1ED7C9 63.02%, rgba(255, 255, 255, 0) 89.06%);
$alert: #FF69B4;
// warning: gradient uses a rotation that is not supported by CSS and may not behave as expected

/* Font Variable */
$generalFontSize: 18px;

@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
$fontStyle: 'Quicksand', sans-serif;
/*
Myriad Pro | Whats the Closest Google Font?
http://joelcrawfordsmith.com/closest-font/font/myriad-pro
 */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
$fontPTSans: 'PT Sans', sans-serif;

/* Padding Variable */

/* Margin Variable */

/*
Export using for js file. e.g.
import v from '_variables.scss';
...
color: v.green,
 */

@mixin blockHoverStyle {
  cursor: pointer;
  background-color: $grey25;
}

:export {
  amwayBlack: $amwayBlack;
  white: $white;
  grey25: $grey25;
  grey50: $grey50;
  grey75: $grey75;
  grey100: $grey100;

  green: $green;
  green2: $green2;
  green4: $green4;
  chartHoverGreen: $chartHoverGreen;
  darkGreen: $darkGreen;
  
  red: $red;
  purple: $purple;
  hoverPurple: $hoverPurple;
  alert: $alert;

  font: $fontPTSans;
}