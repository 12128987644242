// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other
/* 03-nav */
.layout {
  // background-color: #00f;
  /* 03-nav */

  display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // align-items: center;
  width: 1440px;
  height: 104px;
  // top: 528px;
  // left: 480px;
  padding: 24px 80px 0px 80px;
  // gap: 10px;

  box-sizing: border-box;

  // background-image: url('/assets/images/01Homepage/BG Gradient.svg'); //move to jsx
  // background-size: cover;
  // background-position: 0px -200px;
  // background-repeat: no-repeat;
  background-color: transparent;

  z-index: -1;
}

/* 03-nav */
.frame_all {
  // need comment 'background' when used
  // background-color: #0f0;
  /* 03-nav */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 1280px;
  height: 80px;
  padding: 0px 16px 0px 40px;
  border-radius: 100px;
  border: 1px solid v.$grey50;
  box-shadow: 2px 4px 8px 0px #0000001a;

  position: relative;
  box-sizing: border-box;

  /* Neutral/White */
  // background: #ed9d9d;
  background: v.$white;
  // background: linear-gradient(0deg, v.$grey50, v.$grey50),
  //             linear-gradient(0deg, v.$white, v.$white);

  z-index: 1;
}

//---------------------------------------------------------------------------
/* messageImage_1684340039179 */
.frame_left {
  // background-color: #00f;
  /* messageImage_1684340039179 */

  // height: 52px;
  // width: 164.29788208007812px;
  // left: 120px;
  // top: 14px;
  border-radius: 0px;
  // padding-top: 14px;

  /* messageImage_1684340039179 */
  .logo {
    /* messageImage_1684340039179 */

    width: 164.3px;
    height: 52px;

    // background: url(messageImage_1684340039179.jpg);
    mix-blend-mode: multiply;
  }
}

//---------------------------------------------------------------------------
/* Frame 6 */
.frame_menu {
  // background-color: #00f;
  /* Frame 6 */

  // height: 24px;
  // width: 512px;
  left: 459.64892578125px;
  // top: 28px;
  border-radius: 0px;
  // padding-top: 28px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;
}

// for NavList.js
/* Dark Green */
.menu_icon {
  /* Dark Green */
  // color: #073431;
  color: v.$darkGreen;
}

//---------------------------------------------------------------------------
/* Frame 29653 */
.frame_right {
  //background-color: #00f;
  /* Frame 29653 */

  //* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;

  // width: 173px;
  // height: 48px;

  // move to NoneButton.jsx
  // .button {
  //     text_transform: 'none';
  // }

  /* 01-btn-small */
  .button_signup {
    /* 01-btn-small */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    //gap: 10px;

    width: 104px;
    height: 48px;
    box-sizing: border-box;

    /* Green */
    // background: #0DDDCD;
    background: v.$green;
    border-radius: 100px;

    &:hover {
      // cursor: pointer;
      background-color: v.$green;
      border: 1px solid v.$darkGreen;
    }
  }
}

//---------------------------------------------------------------------------
/* Index */
.text_head {
  /* Index */
  // background-color: #f00;

  // width: 41px;
  // height: 24px;
}

/* Log In */
.text_login {
  /* Log In */
  // background-color: #0f0;

  // &:hover {
  //   cursor: pointer;
  // }
}

/* Button */
.text_signup {
  /* Button */
  // background-color: #0f0;

  // width: 56px;
  // height: 24px;
}

//---------------------------------------------------------------------------
/* Search bar */
.frame_search {
  // background-color: burlywood;
  display: flex;
  flex-direction: row;
  width: 755px;
  height: 80px;
  position:absolute;
  align-items: center; 
  justify-content: center;

  // top: 0;
  left: 320px;
  z-index: 1;
  .button_arrow{
    // background-color: aquamarine;
    height:'50px'
  }
}