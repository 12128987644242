@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

//figma 02 Proteins
// Frame 29679
.frame {
  // background-color: #0f0;
  
  // width: 1200;
  height: 58;
  box-sizing: border-box;
  
  display: flex;
  padding: 0px 120px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
//---------------------------------------------------------------------------
/* Food-borne proteins */
