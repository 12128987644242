// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other

/* Purpose */
/* Frame 29655 */
.layout {
  // background-color: #0f0;
  /* Frame 29655 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // padding: 0px 0px 64px;

  // width: 1440px;
  height: 1793px;
  // width: 100%;
  box-sizing: border-box;
}

//---------------------------------------------------------------------------
/* 02-heading1 */
.frame_top {
  // background-color: #00f;
  /* 02-heading1 */
  
  display: flex;
  // width: 1440px;
  padding: 128px 0px 64px 0px;
  flex-direction: column;
  // align-items: center;
  gap: 8px;

  /* Purpose */
  .top_purpose {
    // background-color: #0f0;
    /* Purpose */

    height: 27px;
    width: 184px;
    // left: 628px;
    top: 128px;
    margin-left: 628px;
    
    display: inline-block;
  }

  /* What’s this database for? */
  .top_what {
    // background-color: #f00;
    /* What’s this database for? */

    height: 58px;
    width: 829px;
    // left: 305.5px;
    // top: 163px;
    margin-left: 305.5px;
    
    // display: flex;
    align-items: center;
    text-align: center;
    
    display: inline-block;
  }
}

//---------------------------------------------------------------------------
/* 03-section-2col */
.frame_center1 {
  // background-color: #f00;
  /* 03-section-2col */

  height: 460px;
  // width: 1440px;
  left: 0px;
  top: 285px;
  border-radius: 0px;
  padding: 0px 120px 0px 120px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px 120px;
}

/* 03-section-2col */
.frame_center2 {
  // background-color: #0f0;
  /* 03-section-2col */

  height: 460px;
  // width: 1440px;
  left: 0px;
  top: 745px;
  border-radius: 0px;
  padding: 0px 120px 0px 120px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px 120px;
}

/* 03-section-2col */
.frame_center3 {
  // background-color: #00f;
  /* 03-section-2col */

  height: 460px;
  // width: 1440px;
  left: 0px;
  top: 1205px;
  border-radius: 0px;
  padding: 0px 120px 0px 120px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0px 120px;
}

//---------------------------------------------------------------------------
/* Rectangle 8 */
.center_image {
  /* Rectangle 8 */

  height: 460px;
  width: 600px;
  // left: 120px; //01 03
  // left: 720px; //02
  // top: 0px;
  // border-radius: 0px;
}

/* 02-heading2 */
.center_text {
  // background-color: #00f;
  /* 02-heading2 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 96px;
  // gap: 16px;

  width: 600px;
  height: 460px;

  /* Neutral/Grey 25 - Flood */
  // background: #F4F4F4;
  background: v.$grey25;

  .text_frame {
    display: flex;
    flex-direction: column;
    width: 408px;
    gap: 16px;

    /* Frame 29652 */
    .frame_heading {
      /* Frame 29652 */

      /* Auto layout */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;

      // width: 408px;
      // height: 83px;

      /* Purpose */
      .text_top {
        /* Purpose */

        // width: 408px;
        // height: 27px;
      }

      /* Data Retrieval */
      .text_center {
        /* Data Retrieval */

        // width: 408px;
        // height: 48px; //01 Data retrieval, 03 Modeling Analysis
        // height: 144px; //02 Discovery drug design & functional Food
      }
    }

    /* Provide an intelligent ... */
    .text_bottom {
      /* Provide an intelligent ... */

      // width: 408px;
      // height: 54px;

      //styleName: Body 1;
      line-height: 27px;
      letter-spacing: 0em;

      /* Body 1 */
      // @include size20strong-grey100;
      text-align: left;
    }
  }
}
