@use '/src/assets/scss/variables' as v;

.main {
  // background: #f00;
  height: 350px;
  padding: 16px 16px 24px 16px !important;
  
  .card_img {
    // background: #f00 !important;
    background: var(--neutral-grey-25-flood, #f4f4f4);
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  // 01-card-image
  .imgArea {
    // background: #f00;
    width: 302px;
    height: 250px;
  }
  
  .content {
    // margin-top: 16px;
    margin-top: 8px;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .title {
    color: var(--dark-green, #073431);
    font-family: v.$fontPTSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  .content {
    color: var(--neutral-grey-100-body, #6f6f6f);
    /* Body/B2 */
    font-family: v.$fontPTSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.32px;
    padding-bottom: 0 !important;
  }
}
