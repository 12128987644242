// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other
/* 03-footer */
.layout {
  // need comment 'background' when used
  // background-color: #00f;
  /* 03-footer */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: relative;
  // width: 1440px;
  // height: 536px;
  box-sizing: border-box;

  /* Dark Green */
  // background: #073431;
  background: v.$darkGreen;
}
//---------------------------------------------------------------------------
/* Frame 29619 */
.frame_top {
  // background-color: #f00;
  background-color: transparent;

  /* Frame 29619 */
  box-sizing: border-box;

  display: flex;
  padding: 64px 120px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  height: 176px;

  /* Frame 29617 */
  .top_client {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  /* Frame 29609 */
  .top_map {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    margin-left: 197px;
  }

  /* Frame 29605 */
  .top_icon {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    margin-left: 197px;
  }
}

//---------------------------------------------------------------------------
/* Frame 29621 */
.frame_center {
  // background-color: #0f0;
  background-color: transparent;

  /* Frame 29621 */
  box-sizing: border-box;

  display: flex;
  width: 1440px;
  padding: 48px 120px;
  align-items: center;
  gap: 200px;

  height: 144px;

  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

//---------------------------------------------------------------------------
/* Frame 29622 */
.frame_bottom {
  // background-color: #00f;

  /* Frame 29622 */
  box-sizing: border-box;

  display: flex;
  width: 1440px;
  height: 48px;
  padding: 0px 120px;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid rgba(255, 255, 255, 0.15);
  // background: var(--green, #104C48);
  background: v.$green2;
}

//---------------------------------------------------------------------------
/* Protein */
.text_head {
  /* Protein */
  color: #FFFFFF;
  // width: 76px;
  height: 31px;
}

/* Privacy Policy */
.text_privacy {
  /* Privacy Policy */

  width: 95px;
  // height: 24px;
}

/* Terms & Conditions */
.text_terms {
  /* Terms & Conditions */

  width: 135px;
  // height: 24px;
}
