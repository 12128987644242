@use 'src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

@mixin column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.layout {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 128px 120px;
    justify-content: center;

    width: 100%;
    box-sizing: border-box;

    .main_block {
        height: 400px;
        width: 80%;
        background-color: v.$grey25;
        border: 0.5px solid v.$grey50;
        border-radius: 5px;

        @include column-center;
    }
}

.main_block {
    .title {
        margin-top: 10px;
        font-size: 25px;
        font-weight: 600;
    }
    .content {
        font-size: 18px;
        margin-top: 40px;
    }
    .bottom_button{
        text-transform: none;
        margin-top: 20px;
    }

}