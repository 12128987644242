@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

// Frame 29680
.top_result {
  // background: #f00;

  display: flex;
  padding: 16px 120px 0px 120px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  width: 1200px;
  height: 40px;

  // Frame 29734
  .result_buttons {
    display: flex;
    align-items: center;
    border-radius: 8px;

    // 01-lable-rectangle
    .button {
      // background: #0f0 !important;

      display: flex;
      height: 40px;
      padding: 8px 12px 6px 12px;
      align-items: center;
      // gap: 10px;
      margin-right: 8px;
      font-size: 16px;
      background-color: white;

      border-radius: 8px;
      // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
      border: 1px solid v.$grey50;
    }
  }

  .result_line {
    // background: #f00;

    width: 1px;
    height: 40px;

    // background: #DFDFDF;
    background: v.$grey50;
  }

  // Frame 29732
  .result_texts {
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
  }
}
