@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

//figma 12 Protein Properties
// 02-breadcrumb
.frame {
  // background: #00f;
  /* 02-breadcrumb */

  /* Auto layout */
  // box-sizing: border-box;
  display: flex;
  // width: 1440px;
  // padding: 64px 120px 24px 120px;
  align-items: flex-start;
  
  height: 24px;
  padding: 24px 120px 24px 120px;
}

//---------------------------------------------------------------------------
// figma 11 Protein Properties - Home
.link {
  @include hover-underline;
}
