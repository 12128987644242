@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

//02 Proteins
.layout {
  // background-color: #00f;
  width: 1440px;
}

$header-padding-bottom: 24px;
$header-height: #{72 + 58 + $header-padding-bottom};

//---------------------------------------------------------------------------
/* 03-section-header */
.frame_top {
  // background-color: #0f0 !important;
  /* 03-section-header */
  position: absolute;
  top: 0px;
  padding-bottom: $header-padding-bottom;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding-bottom: 80px;
  box-sizing: border-box;

  width: 1440px;
  // height: 250px; //=112+58+80

  /* Neutral/Grey 25 - Flood */
  // background: #F4F4F4;
  background: v.$grey25;

  /* Frame 29680 */
  .top_desc {
    // background: #f00;
    /* Frame 29732 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 120px;
    gap: 16px;

    // width: 1440px;
    height: 43px;

  }
}

//---------------------------------------------------------------------------
/* Frame 29690 */
.frame_bottom {
  // background-color: #f00 !important;
  /* Frame 29690 */
  position: relative;
  top: $header-height; //03-section-header

  display: flex;
  // width: 1440px;
  // padding: 64px 120px 128px 120px;
  margin-bottom: 200px;
  align-items: flex-start;
  gap: 24px;
  
  padding: 64px 120px 64px 120px;
  // width: 1440px;
  // height: 792px;

  /* Neutral/White */
  // background: #FFFFFF;
  background: v.$white;

  /* Frame 29670 */
  .bottom_sort {
    /* Frame 29670 */

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 40px 0px 0px;

    width: 282px;
    // height: 280px;
    min-height: 280px;

    /* 01-tab */
    .sort_total,
    .sort2nd,
    .sort_mike {
      // background: #ff0 !important;
      /* 01-tab */
      width: 242px;
      box-sizing: border-box;

      display: flex;
      height: 56px;
      padding: 16px 12px;
      // align-items: flex-start;
      gap: 10px;
      align-self: stretch;

      align-items: center;
      justify-content: space-between;
    }
    
    .sort_title {
      // background: #0ff !important;
      display: flex;
      align-self: end;
      align-items: center;
      justify-content: start;
    }
    
    .sort2nd {
      height: 28px;
      padding: 16px 36px;
      
      &:hover {
      	// background: #0f0 !important;
        
      	/* Neutral/Grey 25 - Flood */
      	background: v.$grey25;

      	/* Neutral/Grey 25 - Flood */
      	border-bottom: 1px solid v.$grey25;
      	border-radius: 8px;
      }
    }
    
    .sort_mike {
      &:hover {
      	// background: #0f0 !important;
        
      	/* Neutral/Grey 25 - Flood */
      	// background: #F4F4F4;
      	background: v.$grey25;

      	/* Neutral/Grey 25 - Flood */
      	border-bottom: 1px solid v.$grey25;
      	border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}

$select-background: v.$grey25;
$select-border-bottom: 1px solid v.$grey25;
$select-border-radius: 8px;

/* Frame 29689 */
.bottom_list {
  // background: #0ff;
  /* Frame 29689 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 894px;
  // height: 600px;

  .cards_block {
    max-height: 770px;
    overflow-y: auto;
    padding-right: 24px;
    box-sizing: Border-box;

    /* Frame 29686 */
    .list_bar {
      // background: #0ff;
      /* Frame 29686 */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 24px;
      isolation: isolate;

      width: 894px;
      // height: 120px;

      /* 02-card-3col */
      .bar_box {
        // background: #f0f !important;
        /* 02-card-3col */

        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;

        width: 282px;
        height: 120px;

        /* Neutral/White */
        // background: #FFFFFF;
        // background: v.$white;

        /* Neutral/Grey 50 - DIvider */
        // border: 1px solid #DFDFDF;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        border: 1px solid v.$grey50;

        /* Rectangle 14 */
        .box_img {
          // background: #f00;
          width: 115px;
          // height: 132px
        }

        /* Frame 29681 */
        .box_text {
          // background: #0f0;

          box-sizing: border-box;
          /* Frame 29681 */

          display: flex;
          flex-direction: column;
          justify-content: center;

          width: 167px;
          height: 120px;
          padding: 0px 16px 0px 16px;
        }
      }
    }
  }
}
//---------------------------------------------------------------------------
.text_sort {
  // background: #ff0;
  /* Mike */

  // width: 37px;
  // height: 27px;
  
  // text-decoration: underline;

  // &:hover {
  //   text-decoration: underline;
  //   cursor: pointer;
  // }
}

.text_sort2nd {
  // background: #ff0;

  // width: 37px;
  // height: 27px;
}

/* 10 species • 170 results */
.list_count {
  /* 10 species • 170 results */

  // width: 894px;
  // height: 24px;
}

/* Bovine Milk */
.text_name {
  // background: #ff0;

  // width: 91px;
  // height: 27px;
}

/* Results: 43 */
.text_count {
  // background: #0ff;
  
  // width: 74px;
  // height: 24px;
}

$count-height: 24; //10 species h
$bottom-padding-top: 64; //Frame 29690 padding-top
$bottom-padding-bottom: 128; //Frame 29690 padding-bottom
$bar-height: 120; //Frame 29686 h
$bar-gap: 24; //Frame 29686 gap

// using for js file
:export {
  selectBg: $select-background;
  // selectBg: v.$green; //debug
  selectBorderBottom: $select-border-bottom;
  selectBorderRadius: $select-border-radius;

  paddingHeight: #{$count-height + $bottom-padding-top + $bottom-padding-bottom};
  barHeight: #{$bar-height + $bar-gap};
}