// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other

/* Database Summary */
/* Frame 29656 */
.layout {
  // background-color: #00f !important;
  /* Frame 29656 */
  // width: 1440px;
  box-sizing: border-box;
  
  display: flex;
  // width: 1440px;
  padding: 0px 324px;
  flex-direction: column;
  align-items: center;
  gap: 24px; //frame_top to frame_buttom heigh
  
  /* Neutral/White */
  // background: #FFFFFF;
  background-color: v.$white;
}

//---------------------------------------------------------------------------
/* Frame 29666 */
.frame_top {
  // background-color: #0f0;
  /* Frame 29666 */

  height: 27px;
  width: 792px;
  left: 324px;
  top: 96px;
  border-radius: 0px;
  // margin-left: 324px;
  // margin-top: 96px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 0px;
  gap: 16px;

  /* Database Summary */
  .top_summary {
    /* Database Summary */

    width: 147px;
    height: 27px;

    /* Body 1 */
    @include body1-amwayBlack;
  }
}

/* Frame 29663 */
.frame_bottom {
  // background-color: #00f;
  /* Frame 29663 */

  height: 129px;
  width: 792px;
  left: 324px;
  top: 147px;
  border-radius: 0px;
  // justify: space-between;
  // margin-left: 324px;
  // margin-top: 147px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 34px;

  /* Frame 29661 */
  .bottom_left {
    // background-color: #f00;
    /* Frame 29661 */

    height: 129px;
    width: 209px;
    left: 0px;
    top: 0px;
    border-radius: 0px;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
  }

  /* Frame 29662 */
  .bottom_center {
    // background-color: #0f0;
    /* Frame 29662 */

    height: 129px;
    width: 240px;
    left: 265px;
    top: 0px;
    border-radius: 0px;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
  }

  /* Frame 29663 */
  .bottom_right {
    // background-color: #00f;
    /* Frame 29663 */

    height: 129px;
    width: 231px;
    left: 561px;
    top: 0px;
    border-radius: 0px;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    align-items: flex-start;
    padding: 0px;
  }
}
//---------------------------------------------------------------------------
/* 02-lable */
.bottom_lable {
  /* 02-lable */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 8px 0px;
  gap: 8px;

  // width: 209px;
  height: 43px;

  border-radius: 50px;

  /* checkbox-circle-fill */
  .label_icon {
    /* checkbox-circle-fill */

    height: 24px;
    width: 24px;
    left: 0px;
    // top: 9.5px;
    border-radius: 0px;
  }

  /* 31 */
  .label_number {
    /* 31 */

    height: 27px;
    // width: 20px;
    // left: 32px;
    // top: 8px;

    /* Body 1 - Strong */
    @include body1strong-amwayBlack;
  }

  /* Peptide Classification */
  .label_text {
    /* Peptide Classification */

    height: 24px;
    // width: 149px;
    // left: 60px;
    // top: 9.5px;

    /* Body 2 */
    @include body2-amwayBlack;
  }
}
