@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;

.main {
  width: 100%;
  
  .section_title {
    @include font.heading3;
    color: v.$green;
  }

  .section_sub_title {
    padding-top: 12px;
    padding-bottom: 12px;
    color: v.$grey100;
    
    @include font.body1;
  }
  
  .frame_block {
    border: 1px solid v.$grey50;
    border-radius: 8px;
    // padding: 30px 40px;
  }
  
  .frame_radar {
    display: flex;
    flex-direction: row;
    gap: 8px;
    
    .radar_box {
      height: 400px;
      width: 400px;
    }
  }
}
