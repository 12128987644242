@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

$layout-gap: 24px;

//figma 12 Peptide Properties
// Frame 29727
.layout,
.layout_without_pt {
  // background: #00f;

  // width: 100%;
  box-sizing: border-box;

  display: flex;
  width: 1440px;
  // padding: 64px 120px 128px 120px;
  align-items: flex-start;
  // gap: 24px;

  padding: 64px 120px 64px 120px;
  gap: $layout-gap;
}

.layout_without_pt {
  padding-top: 0;
}

//---------------------------------------------------------------------------
$frame-left-width: 282px;

// 02-panel
.frame_left {
  // background: #f00;

  width: $frame-left-width;
  box-sizing: border-box;

  display: flex;
  // width: 282px;
  // padding-right: 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  padding-right: 40px;
  position: sticky;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;

  // &.MuiTabs-indicator {}
  // &.MuiTabs-indicatorSpan {}

  // 01-tab
  .left_tab {
    // background: #0f0;

    width: 218px;
    box-sizing: border-box;

    display: flex;
    padding: 12px;
    // align-items: center;
    gap: 10px;
    align-self: stretch;

    align-items: flex-start;
    text-align: left;
    text-transform: none;

    &.Mui-selected {
      border-radius: 8px;
      // background: var(--neutral-grey-25-flood, #F4F4F4); 
      // background: var(--neutral-grey-25-flood, v.$grey25); 
      background-color: v.$grey25;
    }

    // &.Mui-focusVisible {}
  }
}

$select-background: v.$grey25;
$select-border-radius: 8px;
//---------------------------------------------------------------------------
$frame-right-width: 1200px - $frame-left-width - $layout-gap;

// 03-table
.frame_right {
  // background: #f00;

  display: flex;
  // width: 792px;
  width: $frame-right-width;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  // display: none;
  // height: 600px;
  // overflow-y: auto;

  // 02-table-cell-protein
  .table_panel {
    margin-bottom: 40px;
  }

  .right_cell {
    // background: #0f0;

    box-sizing: border-box;

    display: flex;
    // height: 60px;
    padding: 12px 0px;
    align-items: center;
    // gap: 24px;
    align-self: stretch;

    gap: $layout-gap;
    min-height: 60px;

    // border-bottom: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    border-bottom: 1px solid var(--neutral-grey-50-d-ivider, v.$grey50);
  }
  .right_cell_last {
    // background: #00f;

    box-sizing: border-box;

    display: flex;
    // height: 60px;
    padding: 12px 0px;
    align-items: center;
    // gap: 24px;
    align-self: stretch;

    gap: $layout-gap;
    min-height: 60px;
  }
  .right_cell_line {
    border-bottom: 1px solid var(--neutral-grey-50-d-ivider, v.$grey50);
  }
  
  $cell-label-width: 282px;

  // Frame 29695
  .cell_label {
    // background: #ff0;

    width: $cell-label-width;
    box-sizing: border-box;

    display: flex;
    // width: 282px;
    padding: 6px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }

  // 01-btn-small
  .cell_button {
    box-sizing: border-box;

    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 100px;
    // background: var(--Neon-Green, #0DDDCD);
    background: v.$green;
    border: 1px solid v.$green;

    &:hover {
      // cursor: pointer;
      // background: var(--Neon-Green, #0DDDCD);
      background: v.$green;
      border: 1px solid v.$darkGreen;
    }
  }

  // Frame 29720
  .cell_value {
    // background: #0ff;

    // width: 486px;
    width: $frame-right-width - $layout-gap - $cell-label-width;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;

    // overflow-x: auto;
    overflow-y: auto;
    max-height: 100vh;
    /*給 Safari 以外的瀏覽器讀取*/
    max-height: calc(var(--vh, 1vh) * 100);
    padding-right: 24px;
    padding-top: 0px;
    padding-bottom: 0px;
    word-break: break-all;
  }
  
  //---------------------------------------------------------------------------
  //figma 11 Protein Properties
  // Frame 1000002378
  .right_cell_code {
    // background: #00f !important;
    
    // width: 894px;
    // height: 285px;
    flex-shrink: 0;
    
    border-radius: 8px;
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: url(<path-to-image>), lightgray -6.105px -7.447px / 101.745% 104.751% no-repeat;
    
    border: 1px solid var(--neutral-grey-50-d-ivider, v.$grey50);
    background: lightgray -6.105px -7.447px / 101.745% 104.751% no-repeat;
    margin: 12px 0px;
    padding: 12px;
  }
  
  // Frame 1000002383
  .right_cell_title {
    // background: #f00;
    
    box-sizing: border-box;
    
    display: flex;
    align-items: center;
    align-self: stretch;
    
    justify-content: space-between;
    width: $frame-right-width;
    min-height: 60px;
  }
  
  // Frame 1000002380
  .title_buttons {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  // Frame 1000002381
  .title_button_select {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    
    border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    background: var(--Neon-Green, #0DDDCD);
  }
  // Frame 1000002382
  .title_button {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 10px;
    
    border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
  }
  
  // 02-table-cell-protein
  .cell_text {
    // background: #00f;
    
    box-sizing: border-box;
    
    display: flex;
    // height: 60px;
    padding: 12px 0px;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    
    min-height: 60px;
  }
  
  // 03-table
  .cell_table {
    // background: #00f;
    
    box-sizing: border-box;
    
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    align-self: stretch;
    
    border-radius: 8px;
    border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    background: #FFF;
    
    padding: 0px;
  }
  .table_sum {    
    border-bottom: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    background: var(--neutral-grey-25-flood, #F4F4F4);
  }
  
  // Frame 1000002391 unuseds
  .cell_chart {
    // background: #00f;
    
    width: $frame-right-width;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
  }
}

.uHover {
  &:hover{
    cursor: pointer;
  }
}

.table_row {
  &:hover {
    background-color: v.$grey25;
  }
}


//---------------------------------------------------------------------------
// using for js file
:export {
  selectBg: $select-background;
  // selectBg: v.$green; //debug
  selectBorderRadius: $select-border-radius;
}