@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;

$border-radius: 5px;
.main {
  // background: #f00 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-50-d-ivider, v.$grey50);
  background: var(--neutral-white, white);

  .left_block {
    display: flex;
    // width: 306px;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    background: var(--Dark-Green, v.$darkGreen);
    
    width: 282px; //=figma 11 Protein Properties > 02-panel

    .step_text {
      align-self: stretch;
      color: var(--Neon-Green, v.$green);
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 27px */
    }

    .title {
      // /* Heading/H4 */
      @include font.heading4;
      align-self: stretch;
      color: var(--neutral-white, white);
      font-feature-settings: 'clig' off, 'liga' off;
      font-style: normal;
    }
  }

  .right_block {
    width: 100%;
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    flex: 100;
    align-self: stretch;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .main_block{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}