@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

$leftPercentage: 30%;
$rightPercentage: 100%-$leftPercentage;
$centerBlockHeight: 650px;

.layout {
  // background-color: #00f;
  margin-top: #{72 + 58 + 24px}; //03-section-header height
  padding-top: 24px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 120px;
  padding-right: 120px;
  justify-content: center;

  width: 1440px;
  box-sizing: border-box;
}

//---------------------------------------------------------------------------
.frame_top {
  // background-color: red;
  //margin-top: 354px; //03-section-header height

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;

}

.top_table {
  // background-color: #f00;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: $centerBlockHeight;
}

.table_left {
  // background-color: #0f0;
  width: $leftPercentage;
  display: flex;
  flex-direction: column;
}

.table_right {
  // background-color: #00f;
  width: $rightPercentage;
  display: flex;
  flex-direction: column;
}

.table_header_left {
  background-color: #073431;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 5px 0 0 0;
}

.table_header_right {
  background-color: #073431;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 40px;

  border-radius: 0 5px 0 0;
}

.header_title {
  // background-color: #0f0;
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}

.header_left {
  width: 100%;
  // background: #0f0;
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  // width: $leftPercentage;

  .text_header_number {
    // background: #800;
    box-sizing: border-box;
    width:32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .text_header_name {
    // background: #880;
    box-sizing: border-box;
    width: 328-64+32px;
    flex: 4;
    padding-left: 4px;
  }

  .text_header_counts {
    // background: #080;
    box-sizing: border-box;
    width: 51+32px;

    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 25px;
  }

  .text_header_percentage {
    // background: #088;
    box-sizing: border-box;
    width: 32+32px;

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8+18px;
  }
}

.header_right {
  // background-color: purple;

  display: flex;
  flex-direction: row;
  // width: $rightPercentage;
  // padding-left: 20px;
  justify-content: center;
  align-content: center;
  width: 100%;

  .text_header {
    // background-color: red;
    display: flex;
    justify-content: center;

    @include body2strong-white;
  }
}

//---------------------------------------------------------------------------

.top_row {
  //background-color: #f00;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  //gap: 5%;

  height: 720px;
}

.table_row_left {
  // background-color: #f00;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: $centerBlockHeight;
  padding: 16px, 16px, 0px, 16px;
  // padding-top:128px;
  box-sizing: border-box;
  
  overflow-y: auto;
  overflow-x: hidden;
}

.row_right {
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;

  height: $centerBlockHeight;

  div {
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row_left,
.row_right {
  align-self: stretch;
}

.top_footer {
  background-color: #073431;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0 0 5px 5px;

  width: 100%;
  height: 64px;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;

  white-space: pre;
}

//---------------------------------------------------------------------------

.frame_bottom {
  //background-color: #0f0;
  width: 100%;

  font-size: 16px;
  color: #073431;
  margin: 64px 120px 128px 120px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.summary {
  display: flex;
  justify-content: space-between;

  .high_light_text {
    color: v.$green;
    padding-left: 5px;
    padding-right: 24px;
  }
}

.caption_divider {
  border-right: 1px solid v.$grey50;
}

.table_row {
  // background: #ff0;
  display: flex;
  border-bottom: v.$grey50 solid 1px;

  .row_data_number {
    // background: #800;
    box-sizing: border-box;
    width: 8+16+32px;

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: transparent;
  }

  .row_data_name {
    // background: #880;
    box-sizing: border-box;
    width: 328-64+32px;
    padding-left: 8px;

    flex: 4;
    border-bottom: transparent;
  }

  .row_data_counts {
    // background: #080;
    box-sizing: border-box;
    // width: 51+32px;
    // flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 16px;
    // border-bottom: transparent;
  }

  .row_data_percentage {
    // background: #088;
    box-sizing: border-box;
    width: 32+32px;

    // padding-right: 16px;
  }
}

// For List And Bar Chart - start
button {
  text-transform: none !important;
}



.text_header_2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 170px;

  .view_chart_btn {
    border: solid white 0.5px !important;
    color: white !important;
  }
}

// For List And Bar Chart - end