// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other

/* 03-cta */
.layout {
  // background-color: #00f;
  /* 03-cta */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // padding: 0px 120px 64px 120px;
  gap: 10px;
  padding-top: 0px;
  padding-left: 120px;
  padding-bottom: 64px;
  padding-right: 120px;

  // width: 1440px;
  height: 200px;
  // width: 100%;
  box-sizing: border-box;

  /* Inside auto layout */
  // flex: none;
  // order: 4;
  align-self: stretch;
  // flex-grow: 0;
  z-index: 4;
}

//---------------------------------------------------------------------------
/* Frame 29668 */
.frame {
  /* Frame 29668 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 40px 64px;
  gap: 10px;

  width: 1200px;
  height: 136px;
  // width: 80%;
  
  border-radius: 16px;
  // background: var(--neon-green, #0DDDCD);
  background: v.$green;

  $item-margin: 64px;
  .frame_text {
    // background: #f00;
    /* Get any questions? We are here to help. */

    height: 42px;
    width: 936px;
    // left: 64px;
    // top: 47px;
    margin-left: $item-margin;
  }
  
  //01-btn-small
  .frame_button {
    // background: #0f0 !important;
    width: 126px;
    height: 56px;
    box-sizing: border-box;
    
    display: flex;
    align-items: flex-start;
    
    
    /* 01-btn-small */
    .button_contact {
      // background: #00f !important;
      // width: 78px;
      // height: 40px;
      // box-sizing: border-box;

      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      
      border-radius: 100px;
      // background: var(--Dark-Green, #073431);
      background: v.$darkGreen;

      /* Body 2 - Strong */
      @include body2strong-white;

      @include button-light;
      border: 1px solid v.$amwayBlack;
    }
  }
}
