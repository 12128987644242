@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;


@mixin btn-type {
  text-transform: none;
  color: v.$green2 !important;
  border: solid 0.5px v.$green2 !important;
}

.chart_block_title {
  width: 100%;
  display: flex;
  justify-content: center;
  @include font.heading4;
}

.chart_type_btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-right: 40px;
  padding-bottom: 12px;

  button {
    @include btn-type;

    &.selected_chart_type {
      background-color: v.$grey50;
    }
  }
}


.formula_block {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;

  .symbol {
    font-size: 45px;
    font-weight: 800;
    padding: 0 16px;
  }

  .multifunctional_peptides_counts,
  .total_peptide_sequences {
    width: 230px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;

    background-color: v.$green2;

    &:hover {
      cursor: pointer;
      background-color: v.$green2Hover;
    }
  }

  .ratio {
    width: 100px;
    height: 80px;
    background-color: v.$green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.btns_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 10px;

  // button {
  //   @include btn-type;
  // }

  .white_btns_block {
    display: flex;
    gap: 16px;
  }

  // .general_btn {
  //   width: 130px;
  // }

  // .search_btn {
  //   margin-left: 40px;
  //   background-color: v.$green2 !important;
  //   color: white !important;
  // }
}

.cls_text_link {
  &:hover {
    cursor: pointer;
  }
}

.sticky_header {
  position: sticky;
  top: 0;
  color: blue;
   z-index: 1;
}