@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/_form_shared.module.scss' as fs;

.main {
  @include fs.no-topbar-layout-main;

  .content {
    @include fs.no-topbar-layout-content;
  }
}
