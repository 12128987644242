@use 'variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/common.scss';

body {
  // TODO: This style can be modify by requirements
  margin: 0;
  font-family:
    // -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    v.$fontPTSans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: v.$fontPTSans !important;
}

code {
  // TODO: This style can be modify by requirements
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

a {
  // TODO: This is an example for using variable of scss, can be deleted
  color: v.$amwayBlack;
  text-decoration: none;
}

.link {
  // color: #1e88e5;
  color: v.$green4;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.App {
  // background-color: #0f0;

  // display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 1440px;
  min-width: 1440px;

  margin-left: calc((100vw - 1440px) / 2);
}