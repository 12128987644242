@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

//12 Protein Properties - 02-breadcrumb
.layout {
  // background-color: #00f;
  
  width: 1200;
  height: 24;
  // box-sizing: border-box;
  
  display: flex;
  // width: 1440px;
  // padding: 64px 120px 24px 120px;
  align-items: flex-start;
  
  padding: 24px 120px 24px 120px;
}

//---------------------------------------------------------------------------
/* 03-nav */
.top_navbar {
  height: 104px; //navbar h
}

/* Home */
.text_home {
  // width: 43px;
  // height: 24px;
}

/* Proteins */
.text_breadcrumb {
  // width: 57px;
  // height: 24px;
}

//figma 11 Protein Properties - Home
.text_breadcrumb2 {
  // color: #f00;
  // color: var(--dark-green, #073431);
}

.text_desc {
  // width: 428px;
  padding-top: 15px;
  height: 27px;
}
