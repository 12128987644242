@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';

$leftPercentage: 40%;
$rightPercentage: 100%-$leftPercentage;
$right-block-height:650px;

.each_row {
  &:hover {
    background-color: v.$grey25;
    cursor: pointer;
  }
}

.each_row[data-selected="true"],
.selected_row {
  background-color: v.$grey25;
}

.layout {
  // background-color: #00f;
  margin-top: #{72 + 58 + 24px}; //03-section-header height
  padding-top: 24px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 120px;
  padding-right: 120px;
  justify-content: center;
  padding-bottom: 30px;

  width: 1440px;
  box-sizing: border-box;
}

//---------------------------------------------------------------------------
.frame_top {
  // background-color: red;
  //margin-top: 354px; //03-section-header height

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;

}

.top_table {
  // background-color: #f00;
  display: flex;
  flex-direction: row;
  height: $right-block-height;
  width: 100%;
}

.table_left {
  // background-color: #0f0;
  width: $leftPercentage;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table_right {
  // background-color: #00f;
  width: $rightPercentage;
  display: flex;
  flex-direction: column;
}

.table_header_left {
  background-color: #073431;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-radius: 5px 0 0 0;
}

.table_header_right {
  background-color: #073431;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-right: 40px;

  border-radius: 0 5px 0 0;
}

.header_title {
  // background-color: #0f0;
  width: 100%;
  height: 64px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
}

.header_left {
  width: 100%;
  // background: #0f0;
  display: flex;
  flex-direction: row;
  padding-right: 10px;

  // width: $leftPercentage;

  .text_header_number {
    // background: #800;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 20px
  }

  .text_header_name {
    // background: #880;

    flex: 6;
    // padding-left: 8px;
  }

  .text_header_counts {
    // background: #080;
    box-sizing: border-box;

    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
  }

  .text_header_percentage {
    // background: #088;
    box-sizing: border-box;
    width: 32+32px;

    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 8+18px;
  }
}

.header_right {
  // background-color: purple;

  display: flex;
  flex-direction: row;
  // width: $rightPercentage;
  // padding-left: 20px;
  justify-content: center;
  align-content: center;
  width: 100%;

  .text_header {
    // background-color: red;
    display: flex;
    justify-content: center;

    @include body2strong-white;
  }
}

//---------------------------------------------------------------------------

.top_row {
  //background-color: #f00;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  //gap: 5%;

  height: 720px;
}

.table_row_left {
  // background-color: #f00;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  height: 720px;
  padding: 16px, 16px, 0px, 16px;
  // padding-top:128px;
  box-sizing: border-box;

  overflow-y: auto;
  overflow-x: hidden;
}

.row_right {
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  height: 100%;
  padding-top: 20px;

  div {
    width: 600px;
    height: $right-block-height - 100px;
    overflow-y: auto;
    box-sizing: border-box;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }

  .btn_block {
    width: 100%;
    padding-right: 100px;
    display: flex;
    justify-content: flex-end;

    button {
      border: solid 0.5px v.$green2 !important;
      color: v.$green2 !important;
    }
  }

}


button {
  text-transform: none !important;
}
