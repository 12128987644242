@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.row_select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}

.select_each_block {
  display: flex;
  gap: 10px;
}

.select_class {
  width: 200px;
  margin-right: 8px;
}

.select_source {
  width: 340px;
  // margin-right: 8px;
}

.select_protein {
  width: 615px;
}
.selectItem_button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:flex-start;
  gap: 8px;

  .button_checked {
    box-sizing: border-box;
    
    display: flex;
    height: 40px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    
    border-radius: 100px;
    // border: 1px solid var(--Dark-Green, #073431);
    border: 1px solid var(--Dark-Green, v.$darkGreen);
    
    color: v.$darkGreen;
    background-color: v.$white;
    @include body2strong-darkGreen;

    &:hover {
      // background-color: v.$white;
      background-color: v.$green;
    }
  }
}

.third_textfield {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.row_button {
  margin: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;

  .button_search {
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 56px;
    // color: v.$darkGreen;
    background-color: v.$green;
    border-radius: 100px;
    @include body1strong-darkGreen;

      &:hover {
      background-color: v.$green;
    }
  }

  .button_reset {
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    height: 56px;
    // color: v.$darkGreen;
    background-color: v.$white;
    border-radius: 100px;
    border: 1px solid var(--Dark-Green, #073431);
    @include body1strong-darkGreen;

    &:hover {
      background-color: v.$white;
    }
  }
}