@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma 08 Food Selection
// Frame 29727
.layout {
  // background-color: #00f;
  margin-top: #{72 + 58 + 24px}; //03-section-header height
  // padding-top: 24px;
  
  box-sizing: border-box;
  display: flex;
  width: 1440px;
  padding: 64px 120px 128px 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

//---------------------------------------------------------------------------
// Frame 29733
.frame_search {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  width: 1200px;
  height: 56px;

  // 01-lable-capsule
  .search_text {
    // background: #0f0;

    box-sizing: border-box;
    display: flex;
    height: 56px;
    padding: 8px 16px 6px 16px;
    align-items: center;
    gap: 10px;

     width: 655px;
    // width: 750px;

    border-radius: 8px;
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    border: 1px solid v.$grey50;
  }

  // Frame 29732
  .search_index {
    // background: #00f;

    display: flex;
    height: 40px;
    align-items: center;
    gap: 4px;
  }

  // Dropdown
  /*.search_menu { //move to jsx
    display: inline-flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    
    border-radius: 8px;
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: #FFF;
    border: 1px solid v.$grey25;
    background: v.$white;

    // Card - Drop Shadow
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.10);
  }*/
}

//---------------------------------------------------------------------------
// 03-table
.frame_table {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
  // background: #FFF;
  border: 1px solid v.$grey50;
  background: v.$white;
}
//---------------------------------------------------------------------------
// Frame 1000002373
.frame_bottom {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  // Pagination
  .bottom_bar {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }


  .divider{
    border-left: 2px solid v.$grey25;
    height: 40px;  
    margin-left: 16px;  
  }

  // Frame 1000002365
  .bar_page,
  .go_to_page_block, .go_to_btn{
    box-sizing: border-box;

    display: flex;
    // width: 40px;
    height: 40px;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // gap: 10px;

    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: var(--neutral-grey-25-flood, #F4F4F4);
    border: 1px solid v.$grey50;
    // background: v.$grey25;

    //Frame 1000002366
    // border: 1px solid var(--neutral-grey-50-d-ivider, #DFDFDF);
    // background: var(--neutral-white, #FFF);
    background: v.$white;

    @include hover-pointer;
  }

  .go_to_page_block {
    margin-left: 15px;
    &:focus-within {
      border-color: v.$darkGreen;
    }
    padding: 10px 14px;
    .go_to_page_input {
      outline: none;
      border: none;
      width: 20px;

      &[type='number'] {
        -moz-appearance: textfield;
      }
      
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .go_to_btn{
    margin-left:5px;
    
    @include body1strong-darkGreen;

    &:hover{
      background-color: v.$grey25;
    }
  }
}

$select-page-bg: v.$grey25;
// $select-page-bg: v.$green; //debug
$unselect-page-bg: v.$white;

:export {
  selectBg: $select-page-bg;
  unselectBg: $unselect-page-bg;
}

.row_select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;
}