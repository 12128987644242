@use '/src/assets/scss/variables' as v;

.layout {
  // background-color: #00f;
  margin-top: 154px; //03-section-header - 03-nav height
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 128px 120px;
  justify-content: center;
  
  padding: 24px 120px 0px 120px;

  width: 100%;
  box-sizing: border-box;
}