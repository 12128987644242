// @use 'src/assets/scss/variables' as v;

@mixin logo-style {
  // font-family: v.$logo-font-family  !important;
  font-size: 25px;
  font-weight: 500;
  padding-bottom: 1em;
}

@mixin no-topbar-layout-main {
  height: 100%;
  padding: 20px;
  overflow-y: auto;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
}

@mixin no-topbar-layout-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-top: 4rem;
  // border: v.$border-type;
  // max-width: 350px;
  max-width: 400px;
  box-shadow: 0.1px 0.1px 0.8px rgba(0, 0, 0, 0.7);
  padding: 30px;
  padding-bottom: 20px;
}

@mixin fullWidth {
  width: 100%;
}

@mixin each-block-pb-1em {
  padding-bottom: 1em;
}

@mixin row-center {
  display: flex;
  justify-content: center;
}

$mb4: 4px;

@mixin button-hover {
  &:hover {
    // background-color: v.$mainColor;
  }
}

@mixin more-padding-of-rl {
  padding-left: 10px;
  padding-right: 10px;
}

@mixin info-text-filed {
  @include each-block-pb-1em;
  @include fullWidth;
  @include row-center;

  .input-field {
    margin-bottom: $mb4;
  }

  // .Mui-focused .MuiOutlinedInput-notchedOutline{
  //     background-color:red  !important;
  //     border-color: v.$mainColor !important;
  //   }
  // .Mui-focused{
  //     border-color: v.$mainColor !important;
  // }
}

@mixin smaller-font {
  font-size: 12px;
}
