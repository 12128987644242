@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';
// @import '/src/assets/scss/variables';

.frame_intro {
  // background: #f00 !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: left;
  // align-items: center;
  align-self: stretch;
  border: 1px solid var(--neutral-grey-50-d-ivider, v.$grey50);
  background: var(--neutral-white, white);
  
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 8px;
}

.manual_left,
.manual_right {
  // background: #0ff !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.manual_left {
  width: 55%;
}
.manual_right {
  width: 45%;
}

.manual_btn_box {
  // background: #00f;
  display: flex;
  flex-direction: row;
}

.input_box {
  // background: #f00;
  // margin: 0;
  // flex: 1;
  width: 97% !important;
}

.example_box {
  // background: #0f0 !important;
  background: v.$grey25;
  // margin: 0;
  // flex: 1;
  // width: 97%;
}

.loading_btn {
  margin-right: 10px !important;
  background-color: v.$grey50 !important;
  color: v.$amwayBlack;
  width: 90px;
}
