@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

//figma 12 Protein Properties
// 02-card
.layout {
  // background-color: #00f;
  
  width: 1200;
  height: 117;
  // box-sizing: border-box;
  
  display: flex;
  padding: 0px 120px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

// Frame 29679
.frame {
  display: flex;
  padding: 24px 40px;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  
  border-radius: 8px;
  // background: var(--neutral-grey-25-flood, #F4F4F4);
  background: var(--neutral-grey-25-flood, v.$grey25);
}
//---------------------------------------------------------------------------

// Frame 1000002374
.frame_1 {
  // background: #f00;
  
  width: 256px;
  // height: 69;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// Frame 1000002375
// Frame 1000002376
.frame_2 {
  // background: #0f0;
  
  width: 208px;
  // height: 48;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  word-break: break-all;
}

.frame_34 {
  // background: #00f;
  
  width: 208px;
  // height: 48;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.line {
  // background-color: #f00 !important;
  
  width: 1px;
  height: 69px;
  
  // background: #DFDFDF;
  background: v.$grey50;
}
//---------------------------------------------------------------------------
