// @use '../variables' as v;
@use '/src/assets/scss/variables' as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

// figma default value
// modify: copy original line to change value. keep original value on code
// nonuse: comment code for figma value; delete code for other

/* 03-section-hero */
.layout {
  // background-color: #00f !important;
  /* 03-section-hero */

  display: flex;
  // padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 128px;
  isolation: isolate;

  // width: 1440px;
  // height: 750px; //with bg image
  // height: 750px - 104px; // cut '03-nav' height
  box-sizing: border-box;

  padding-bottom: 128px;

  /* Neutral/White */
  // background: #FFFFFF;
  background: v.$white;
}

//---------------------------------------------------------------------------
/* BG Gradient */
.bg {
  // background-color: #00f;
  /* BG Gradient */

  // height: 1199.0001220703125px;
  // width: 2098px;
  // left: -329px;
  // top: -150px;
  // border-radius: 0px;

  position: absolute;
  // width: 2098px;
  // height: 1199px;
  // left: calc(50% - 2098px/2);
  // top: 0px;

  /* for background-image */
  width: 1440px;
  // height: 750px - 104px;
  top: 0px;

  // background-image: url('/assets/images/01Homepage/BG Gradient.svg');
  // background-size: cover;
  // background-position: 0px -104px;
  // background-repeat: no-repeat;

  /* Inside auto layout */
  z-index: -1;
}

/* Frame 29682 */
.frame {
  // background-color: #00f;
  /* Frame 29682 */

  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  gap: 54px;

  align-items: center;

  // width: 1440px;
  height: 390px;
  width: 100%;

  margin-top: 128px;
}

//---------------------------------------------------------------------------
/* Frame 29631 */
.frame_text {
  // background-color: #00f;
  /* Frame 29631 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  // width: 1440px;
  height: 186px;

  /* Comprehensive database of food-derived bioactive peptides */
  .text_title {
    // background: #f00;
    /* Comprehensive database of food-derived bioactive peptides */

    // width: 719px;
    height: 116px;

    display: flex;
    align-items: center;
    text-align: center;
  }

  /* We have 6289 entries of food-derived ... */
  .text_number {
    // background: #f00;
    /* We have 6289 entries of food-derived ... */

    // width: 491px;
    height: 54px;

    display: flex;
    align-items: center;
    text-align: center;
  }
}

//---------------------------------------------------------------------------
/* Peptide */
.frame_search {
  // background-color: #00f;
  /* Peptide */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  // width: 1440px;
  height: 151px;
}

//---------------------------------------------------------------------------
/* Frame 29629 */
.search_input {
  // background-color: #0f0;
  /* Frame 29629 */

  height: 95px;
  // width: 1440px;
  // left: 0px;
  // top: 0px;
  // border-radius: 0px;
  // padding: 0px 426px 0px 426px;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 0px 426px;

  /* Frame 29630 */
  .input_top {
    /* Frame 29630 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 10px;

    width: 588px;
    height: 40px;

    /* Frame 29627 */
    .top_tab {
      // background: #f0f;
      /* Frame 29627 */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      // gap: 8px;

      // width: 176px;
      // height: 40px;

      //select
      .tab_left {
        /* 01-label-search-tab */

        /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 8px 16px;
        // gap: 10px;
        margin-right: 8px;

        width: 86px;
        // height: 40px;

        /* Dark Green */
        // background: #073431;
        border-radius: 8px 8px 0px 0px;
        // background: v.$darkGreen;
        /* move to $select-xxx, setting by jsx */

        box-sizing: border-box;
        border: 1px solid v.$amwayBlack;

        /* Text */
        /* Body 2 */
        @include body2;

        /* Neutral/White */
        // color: #FFFFFF;
        // color: v.$white;
        /* move to $select-xxx, setting by jsx */
      }

      //unselect
      .tab_right {
        /* 01-label-search-tab */

        box-sizing: border-box;

        /* Auto layout */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding: 8px 16px;
        // gap: 10px;

        width: 82px;
        // height: 40px;

        /* Neutral/Grey 25 - Flood */
        // background: #F4F4F4;
        // background: v.$grey25;
        /* move to $unselect-xxx, setting by jsx */

        /* Neutral/Amway Black - Heading */
        // border: 1px solid #2C2C2C;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid v.$amwayBlack;

        /* Text */
        /* Body 2 */
        @include body2;

        /* Dark Green */
        // color: #073431;
        // color: v.$darkGreen;
        /* move to $unselect-xxx, setting by jsx */
      }
    }
  }

  /* Frame 29626 */
  .input_center {
    // background: #00f;
    /* Frame 29626 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 588px;
    height: 56px;

    /* Neutral/White */
    // background: #FFFFFF;
    background-color: v.$white;

    /* Neutral/Grey 50 - DIvider */
    // border: 1px solid #DFDFDF;
    border-radius: 100px;
    border: 1px solid v.$grey50;

    z-index: 2;

    /* .Base-Input */
    .center_type {
      /* .Base-Input */

      /* Neutral/Grey 50 - DIvider */
      // border-right: 1px solid #DFDFDF;
      border-right: 1px solid v.$grey50;

      /* Input Field */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      // padding: 10px 16px 10px 24px;
      // gap: 8px;
      padding-left: 16px;

      width: 176px;
      height: 56px;
    }

    /* .Base-Input */
    /* Input Field */
    .center_word {
      /* Input Field */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      align-items: center;
      // padding: 10px 24px 10px 16px;
      // gap: 8px;
      padding-left: 24px;

      width: 315px;
      height: 56px;
    }

    /* 01-btn-small */
    .button_search {
      /* 01-btn-small */

      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // padding: 8px 24px;

      width: 97px;
      height: 56px;

      /* Green */
      // background: #0DDDCD;
      background: v.$green;
      border-radius: 100px;

      /* Button */
      /* Body 2 - Strong */
      @include body2strong-darkGreen;

      &:hover {
        cursor: pointer;
        border: 1px solid v.$darkGreen;
      }
    }
  }
}
//----Search2--------------
.frame_search3 {
  // background-color: rgb(179, 179, 230);
  /* Peptide */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;

  padding: 0px;
  // gap: 16px;

  // width: 800px;
  height: 80px; 
}
.search_input3 {
  // background-color: rgb(244, 206, 240);

  height: 80px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .input_top {
    // background-color: bisque;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 10px;

    width: 600px;
    height: 28px;//40*0.84

    .top_tab {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;

      // width: 176px;
      // height: 40px;

      //select
      .tab_left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-right: 8px;

        width: 86px;

        border-radius: 8px 8px 0px 0px;

        box-sizing: border-box;
        border: 1px solid v.$amwayBlack;
        @include body2;
      }

      //unselect
      .tab_right {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 82px;
        // height: 40px;

        border-radius: 8px 8px 0px 0px;
        border: 1px solid v.$amwayBlack;

        @include body2;
      }
    }
  }

  .input_center {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    width: 600px;
    height: 44px;//56*0.84
    background-color: v.$white;

    border-radius: 100px;
    border: 1px solid v.$grey50;

    z-index: 2;

    .center_type {
      border-right: 1px solid v.$grey50;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;

      width: 25%;
      height: 40px;
    }

    .center_word {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 24px;

      width: 60%;
      height: 40px;
    }

    .button_search {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      width: 15%;
      height: 40px;
      background: v.$green;
      border-radius: 100px;
      @include body2strong-darkGreen;

      &:hover {
        cursor: pointer;
        border: 1px solid v.$darkGreen;
      }
    }
  }
}
// .search3 {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   // height: 48px;
//   background-color: red;

// }

// from .tab_left
$select-color: v.$white;
$select-background: v.$darkGreen;
// from .tab_right
$unselect-color: v.$darkGreen;
$unselect-background: v.$grey25;

//---------------------------------------------------------------------------
/* Frame 29653 */
.search_option {
  // background-color: #f00;
  /* Frame 29653 */

  height: 40px;
  // width: 1440px;
  left: 0px;
  top: 111px;
  // border-radius: 0px;
  // padding: 0px 426px 0px 426px;
  // justify: space-between;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  // padding: 0px 426px;
  // gap: 4px;

  // Multifunctional Peptide
  /* 01-lable-capsule */
  .option_multi {
    // background-color: #ff0;
    /* 01-lable-capsule */

    // height: 40px;
    // width: 181px;
    // left: 426px;
    // top: 0px;
    // border-radius: 0px;
    padding: 8px 8px 6px 8px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 8px 6px;
    gap: 10px;

    /* Peptide Classification */

    width: 165px;
    height: 24px;
  }

  // Advanced Search
  /* 01-lable-capsule */
  .option_advanced {
    // background-color: #0ff;
    /* 01-lable-capsule */

    // height: 40px;
    // width: 136px;
    // left: 878px;
    top: 0px;
    // border-radius: 8px;
    padding: 8px 8px 6px 8px;
    margin-left: 878px - 426px - 165px;

    /* Auto layout */
    // display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 8px 8px 6px;
    // gap: 10px;
    display: inline-block;

    /* Peptide Classification */

    width: 120px;
    height: 24px;
  }

  // from /* Frame 29653 */
  .option_milk {
    // background-color: #ff0;
    /* 01-lable-capsule */

    // height: 40px;
    // width: 46px;
    // left: 426px;
    // top: 0px;
    // border-radius: 0px;
    // padding: 8px 8px 6px 8px;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 6px;
    padding-left: 8px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;

    /* Peptide Classification */

    // width: 30px;
    height: 24px;
  }

  .option_plant {
    // background-color: #f0f;
    /* 01-lable-capsule */

    // height: 40px;
    // width: 46px;
    // left: 426px;
    // top: 0px;
    // border-radius: 0px;
    // padding: 8px 8px 6px 8px;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;

    /* Plant */

    // width: 30px;
    height: 24px;
  }

  /* Line 1 */
  .text_line {
    /* Line 1 */

    // height: 0px;
    // width: 20px;
    // left: 476px;
    // top: 10px;
    height: 20px;
    width: 0px;
    margin-top: 10px;

    /* Dark Green */
    // border: 1px solid #073431;
    border: 1px solid v.$darkGreen;
    // transform: rotate(90deg);
  }
}

// using for js file
:export {
  selectColor: $select-color;
  selectBg: $select-background;

  unselectColor: $unselect-color;
  unselectBg: $unselect-background;
}