@use 'src/assets/scss/variables' as v;
@use 'src/assets/scss/_form_shared.module.scss' as fs;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.main {
  @include fs.no-topbar-layout-main;

  .content {
    @include fs.no-topbar-layout-content;
    justify-content: center;
    min-height: 350px;
    max-height: 380px;

    .logo {
      @include fs.logo-style;
      font-size: 40px;
      flex: 1;
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }

    .info_text_filed {
      flex: 1;
      @include fs.info-text-filed;
    }

    .login_btn_block {
      @include fs.each-block-pb-1em;
      @include fs.fullWidth;
      @include fs.more-padding-of-rl;
      display: flex;
      flex-direction: column;

      .login_btn {
        @include fs.fullWidth;
        @include fs.button-hover;
        margin-bottom: fs.$mb4;

        height: 48px;
        border-radius: 100px;
        box-shadow: none;
        text-transform: none;
        @include body2strong;

        &:hover {
          // cursor: pointer;
          border: 1px solid v.$darkGreen;
        }

        &:enabled {
          background: v.$green;
          @include body2strong-darkGreen;
        }
      }
    }

    .bottom_block {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include fs.smaller-font;

      @include body2-darkGreen;
    }
  }
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  // margin-bottom: v.$text-field-mb !important;
}
