// @use '../variables' as v;
@use '/src/assets/scss/variables'as v;
// @use '../font.scss' as f;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.layout {
  // background-color: #00f;
  margin-top: 250px; //03-section-header - 03-nav height

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 128px 120px;
  justify-content: center;

  width: 100%;
  box-sizing: border-box;
}



.terms_content {
  color:#073431;
  //background-color: purple;
  margin-bottom: 100px;
}

//---------------------------------------------------------------------------
.frame_button{
  display: flex;
  flex-direction: row;
  //background-color: purple;
}
/* 01-btn-small */
.button_agree {
  /* 01-btn-small */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  //gap: 10px;
  margin: 0 10px;

  width: 104px;
  height: 48px;

  /* Green */
  // background: #0DDDCD;
  background: v.$green;
  border-radius: 100px;

  &:hover {
    cursor: pointer;
    background-color: v.$green;
    border: 1px solid v.$darkGreen;
  }
}
// .button_agree{
//   background-color: red;
// }
.button_disagree {
  /* 01-btn-small */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  //gap: 10px;
  margin: 0 10px;

  width: 104px;
  height: 48px;

  /* Green */
   //background: #0DDDCD;
  background: v.$grey25;
  border-radius: 100px;

  &:hover {
    cursor: pointer;
    background-color: v.$grey25;
    border: 1px solid v.$grey100;
  }
}

.text_login {
  /* Log In */
  //background-color: #0f0;

  // &:hover {
  //   cursor: pointer;
  // }
}