@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;


@mixin btn-type {
  text-transform: none;
  color: v.$green2 !important;
  border: solid 0.5px v.$green2 !important;
}

.chart_type_btns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  padding-right: 40px;
  padding-bottom: 12px;

  button {
    @include btn-type;

    &.selected_chart_type {
      background-color: v.$grey50;
    }
  }
}
