@use '/src/assets/scss/variables' as v;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

@mixin button-general-style {
  padding: 8px 24px !important;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 56px !important;
  border-radius: 100px !important;
  color: v.$darkGreen !important;
  font-weight: 600 !important;
  line-height: 150% !important;
  /* 24px */
  @include body1strong-darkGreen;
}

.green_button {
  background-color: v.$green !important;
  @include button-general-style;

  &:hover {
    background-color: v.$green;
  }
}

.white_button {
  background-color: v.$white !important;
  border: 1px solid v.$darkGreen !important;
  @include button-general-style;

  &:hover {
    background-color: v.$white;
  }
}