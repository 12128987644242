@use '/src/assets/scss/variables' as v;

$radius: 5px;

.main {
  display: flex;
  flex-wrap: wrap;

  .each-bar,
  .full-bar {
    display: flex;
    flex: 0 0 calc(50% - 10px);
    padding: 5px;

    .title {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      width: 500px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .each-bar {
    &:hover {
      .title {
        text-decoration: underline;
      }

      cursor: pointer;
    }
  }



  .bar-base {
    width: 100%;
    height: 20px;
    background: v.$grey50;
    border-radius: $radius;
  }

  @mixin fill-bar {
    height: 100%;
    background: v.$green;
  }

  .bar-proportion {
    @include fill-bar;
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
    padding-left: 5px;
  }


  .full-bar-proportion {
    @include fill-bar;
    border-radius: $radius;
    width: 100%;
  }
}