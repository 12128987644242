@use 'src/assets/scss/_variables'as v;
// @use './_shareVar.module.scss' as s;
@use 'src/assets/scss/_form_shared.module.scss'as fs;
@import '/src/assets/scss/font';
@import '/src/assets/scss/pseudo';

.main {
  // background: #f00;
  // @include s.register-main;

  .logo {
    @include fs.logo-style;
  }

  .top_illustration {
    @include fs.each-block-pb-1em;
    @include fs.fullWidth;
    @include fs.row-center;

    @include body2strong;
  }

  .info_text_filed {
    @include fs.info_text_filed;
  }

  .bottom_illustration {
    @include fs.each-block-pb-1em;
    @include fs.fullWidth;
    @include fs.smaller-font;
    @include fs.more-padding-of-rl;
    // margin: 10px 0;
    @include body2;
  }

  .submit_btn_block {
    @include fs.each-block-pb-1em;
    @include fs.fullWidth;
    @include fs.more-padding-of-rl;
    display: flex;
    flex-direction: column;

    .submit_btn {
      @include fs.fullWidth;
      @include fs.button-hover;
      margin-bottom: fs.$mb4;

      height: 48px;
      border-radius: 100px;
      box-shadow: none;
      text-transform: none;
      @include body2strong;

      &:hover {
        // cursor: pointer;
        border: 1px solid v.$darkGreen;
      }

      &:enabled {
        background: v.$green;
        @include body2strong-darkGreen;
      }
    }
  }

  .go_login_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    // @include fs.smaller-font;
    @include body2-darkGreen;

    .go_login_text {
      @include link-text;
      @include body2-darkGreen;
    }
  }
}

.passwd_err_help_block {
  margin-bottom: fs.$mb4;

  .err_list_item {
    padding: 2px 16px;
  }

  .err_text {
    margin-left: 5px;
    @include fs.smaller-font;
  }
}

.feedback {
  height: 80px;
  width: 100%;
  background-color: rgba($color: v.$green, $alpha: 0.2);
  margin-bottom: 20PX;
  display: flex;
  justify-content: center;
  align-items: center;

  .text {
    padding-left: 5px;
  }
}