@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';

.frame_muation {
  // background: #f00;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  align-self: stretch;

  width: 1200px;
  height: 56px;
}

.muation_table {
  padding-left: 8px;
}

.table_body_cell_1st,
.table_body_cell {
  font-size: 16px !important;
  min-width: 16px;
  
  background: v.$white;
}

.table_body_cell_1st {
  width: 100px;
  
  background: v.$grey25;
}
