@use '/src/assets/scss/variables' as v;
@use '/src/assets/scss/font' as font;
// @import '/src/assets/scss/font';
// @import '/src/assets/scss/pseudo';

.main {
  // background: #f00;
  
  // width: 100%;
  display: flex;
  box-sizing: border-box;
}

.table_body_cell {
  font-size: 12px !important;
  
  // padding: 2px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.table_body_cell_1st {
  color: v.$white !important;
  background: v.$grey100 !important;
  
  // &.MuiTableCell-root {
  //   color: v.$white;
  //   background: v.$darkGreen;
  // }
}